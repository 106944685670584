
import { Component, Prop, Vue } from "vue-property-decorator";
import FacilityService from "@/services/facilities";
import FacilityModule from "@/store/facility";
import { CreateHospital } from "@/@types/management";
import { deleteNullProp, getPhoneNumber3, parsePhoneNumber } from "@/utils/Util";
import FormSet from "@/components/FormSet.vue";
import Button from "@/components/Button.vue";

@Component({
  components: {
    FormSet,
    Button,
  },
})
export default class HospitalEditForm extends Vue {
  @Prop({ type: Boolean })
  private readonly isReadOnly!: boolean;

  private loading = false;
  private submitLoading = false;
  private animalTypes = [];
  private form: CreateHospital = {
    id: "",
    name: null,
    tel: null,
    address: null,
    director: "-",
    email: "",
    url: "",
    access: "",
    must_memo: false,
    animal_types: [],
    doctor_schedule: "",
  };
  private phoneNumber = {
    number1: "",
    number2: "",
    number3: "",
  };
  private facilityLink = `${process.env.VUE_APP_BASE_URL}/course-select?id=`;
  private copySuccess = false;

  private get hospitalId(): string {
    return FacilityModule.hospitalId;
  }
  private showModal(name: string) {
    this.$modal.show(name);
  }

  private closeModal(name: string) {
    this.$modal.hide(name);
  }

  private linkCopy(text: string) {
    this.$copyText(text);
    this.copySuccess = true;
    setTimeout(() => {
      this.copySuccess = false;
    }, 3000);
  }

  private mounted(): void {
    this.getHospitalInfo();
    this.getAnimalTypes();
  }

  private async getHospitalInfo(): Promise<void> {
    try {
      this.loading = true;
      const res = await FacilityService.get(this.hospitalId);
      if (res.data.data[0]) {
        this.form = res.data.data[0];
      }
      if (this.form.tel) this.phoneNumber = getPhoneNumber3(this.form.tel);
      this.loading = false;
    } catch (error: any) {
      this.loading = false;
      throw new Error(error);
    }
  }

  private async getAnimalTypes(): Promise<void> {
    try {
      const res = await FacilityService.getAnimalTypes();
      this.animalTypes = res.data.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  private async submit(): Promise<void> {
    try {
      this.submitLoading = true;
      if (this.form.id) {
        this.form.tel =
          this.phoneNumber.number1 && this.phoneNumber.number2 && this.phoneNumber.number3
            ? parsePhoneNumber(this.phoneNumber.number1 + this.phoneNumber.number2 + this.phoneNumber.number3)
            : "";
        const payload = deleteNullProp(this.form);
        const res = await FacilityService.update(this.hospitalId, payload);
        FacilityModule.changeHospitalName(res.data.data[0].name);
        this.$router.push({ name: "HospitalDetail" });
      }
    } catch (error: any) {
      this.showModal("saveError");
      this.submitLoading = false;
      throw new Error(error);
    }
  }
}


import { Component, Prop } from "vue-property-decorator";
import HospitalHeader from "@/components/HospitalHeader.vue";
import ManagementFooter from "@/components/ManagementFooter.vue";
import CheckCommonLogin from "@/utils/CheckCommonLogin";

@Component({
  components: {
    HospitalHeader,
    ManagementFooter,
  },
})
export default class HospitalDetail extends CheckCommonLogin {
  @Prop({ type: String })
  title!: string;
}
